<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>推荐企业</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in gongsiList" :key="index" @click="goTab(item.id)">
                        <div class="nav-p" :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path:'/cyppch'}">产业品牌出海</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item>品牌出海行业分类</el-breadcrumb-item> -->
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="details-text">{{detailsList.name}}</div>
                    <div class="details-p" v-html="detailsList.content"></div>
                    <div class="button" @click="goUrl(detailsList.link)">
                        <img src="../../assets/images/button.png" alt="">
                        <p>点此进入平台</p>
                    </div>

                    
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getplatexts,getdetails} from '../../api/index'
    export default {
        data() {
            return {
                plate_id:'',
                id:'',  
                gongsiList:[],
                detailsList:[]            
            }
        },
        created() {
            this.plate_id=this.$route.query.plate_id
            this.id=this.$route.query.id
            this.key=this.$route.query.key              
            this.getdetails()        
            if(this.key){
                if(this.key==0){
                    this.getplatextsgs()
                }else if(this.key==1){
                    this.getplatexts() 
                }
            }    
        },
        methods: {
            // 跳转外链
            goUrl(url){
               window.open(url) 
            },
            goTab(id){
                this.id=id;
                this.getdetails();
            }, 
            // 品牌出海
            getplatexts(){
                getplatexts({
                    plate_id:this.plate_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.gongsiList=res.data.data
                    }
                })
            },
            // 公司推荐
            getplatextsgs(){
                getplatexts({
                    plate_id:20,
                    recom:1
                }).then((res)=>{
                    // console.log(res)
                    this.gongsiList=res.data.data

                })
            },
            //详情
            getdetails(){
                getdetails({
                    id:this.id
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.detailsList=res.data
                    }
                })
            }

        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                max-height: 70vh;
                overflow: auto;
                &::-webkit-scrollbar {
                width:5px;
                }
                /* 滚动槽 */
                &::-webkit-scrollbar-track {
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
                border-radius:10px;
                }
                /* 滚动条滑块 */
                &::-webkit-scrollbar-thumb {
                border-radius:10px;
                background:rgba(0,0,0,0.1);
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
                }
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    .nav-p{
                        flex: 1;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    p{color: #0071DB;font-size: 22px;font-weight: bold;flex-shrink: 0;margin-left: 5px;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 0;
                .details-text{color: #555555;font-size: 28px;margin-bottom: 40px;text-align: center;}
                .details-p{color: #666666;font-size: 16px;line-height: 32px; padding: 0 50px;}   
                .button{
                    width: 151px;height: 43px;position: relative;margin:80px auto;cursor: pointer;
                    img{width: 100%;height: 100%;}
                    p{width: 151px;height: 43px;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;line-height: 43px;text-align: center;}
                } 
            }
        }
        
    }

</style>